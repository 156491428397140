<template>
  <div>
    <div class="container text-center vh-100 pt-5" v-if="isLoading">
      <img
        src="https://greenified.se/wp-content/themes/input/assets/images/Rolling-1.1s-81px.svg"
        width="50"
      />
      <p style="padding-top: 46px; text-align: center">Please wait...</p>
    </div>

    <div class="loginform form" v-if="!isLoading">
      <h1 class="headline mb-23">New location</h1>
      <div class="form-group mb-23">
        <autocomplete
          ref="locationInput"
          :search="search"
          @submit="handleSubmit"
        ></autocomplete>
      </div>
      <div class="col-md-12 text-center">
        <button type="submit" class="btn btn-black" @click="setLocation">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import RentalAPI from "@/services/api/rental";
export default {
  name: "ChangeLocation",
  props: {
    id: String,
  },
  components: {
    Autocomplete,
  },
  data() {
    return {
      isLoading: false,
      locations: {},
      showLoginForm: false,
      location: "",
      api: process.env.VUE_APP_LARAVEL_API,
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    search: function (input) {
      if (input.length < 1) {
        return [];
      }
      return this.locations.filter((country) => {
        return country.toLowerCase().includes(input.toLowerCase());
      });
    },
    getLocations: function () {
      RentalAPI.getLocations(this.id).then((response) => {
        this.locations = response.data.map((a) => a.column_value);
      });
    },

    handleSubmit(result) {
      this.location = result || this.$refs.locationInput.value;
    },

    setLocation: function () {
      this.isLoading = true;
      this.location = this.$refs.locationInput.value;
      RentalAPI.setLocation(this.id, this.location)
        .then(() => {
          this.isLoading = false;
          this.$toast.success("Location updated.", {
            timeout: 3000,
          });
          this.$emit("reload");
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.error("Unable to update location.", {
            timeout: 3000,
          });
        });
    },
  },
};
</script>
