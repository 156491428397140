import api from "@/services/api";

export default {
  getFixedAsset(id) {
    return api().get("/item/" + id);
  },

  login(credentials) {
    return api().post("/auth/login", {
      username: credentials.username,
      password: credentials.password,
    });
  },

  sendServiceRequest(id, message) {
    return api().post("/item/" + id + "/service", { message: message });
  },

  setLocation(id, location) {
    return api().post("/item/" + id + "/location", { location: location });
  },

  getLocations(id, location) {
    return api().get("/item/" + id + "/locations", { location: location });
  },
};
