var render = function render(){var _vm=this,_c=_vm._self._c;return _c('simple-layout',[_c('div',{staticStyle:{"background":"#f7f5ee !important","padding-left":"10px","padding-right":"10px"}},[(_vm.isLoading)?_c('div',{staticClass:"container text-center vh-100 pt-5"},[_c('img',{attrs:{"src":"https://greenified.se/wp-content/themes/input/assets/images/Rolling-1.1s-81px.svg","width":"50"}}),_c('p',{staticStyle:{"padding-top":"46px","text-align":"center"}},[_vm._v("Loading product...")])]):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"container asset-info"},[(_vm.isMissingLocation)?[_c('ChangeLocation',{attrs:{"id":_vm.id},on:{"reload":_vm.getFixedAsset}})]:_vm._e(),_vm._l((_vm.product),function(section){return _c('div',{key:section.id,staticClass:"row"},[_c('div',{staticClass:"col"},[(section.type === 'M')?_c('h1',{staticClass:"headline"},[_vm._v(" "+_vm._s(section.headline.toLowerCase())+" ")]):_vm._e(),(section.type === 'S')?_c('div',{staticClass:"row small-headline-wrapper"},[_c('div',{staticClass:"col-4 small-headline"},[_vm._v(" "+_vm._s(section.headline.toLowerCase())+" ")]),_c('div',{staticClass:"col-8"},[_vm._v(_vm._s(section.text))])]):_vm._e(),(section.type === 'L')?_c('div',{staticClass:"row small-headline-wrapper"},[_c('div',{staticClass:"col-12 text-center"},[_c('a',{attrs:{"target":"_blank","href":'http://' + section.link}},[_vm._v(_vm._s(section.headline))])])]):_vm._e(),(section.type === 'D')?_c('div',{staticClass:"row small-headline-wrapper"},[_c('div',{staticClass:"col-12 text-center"},[_c('a',{attrs:{"href":_vm.api +
                  '/api/' +
                  _vm.$store.state.language +
                  '/item/' +
                  _vm.$route.params.id +
                  '/file/' +
                  section.id}},[_vm._v(_vm._s(section.headline))])])]):_vm._e(),(section.type === 'B')?_c('div',{staticClass:"row small-headline-wrapper"},[_c('div',{staticClass:"col-12 text-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.api +
                  '/api/' +
                  _vm.$store.state.language +
                  '/item/' +
                  _vm.$route.params.id +
                  '/image/' +
                  section.id}})])]):_vm._e()])])})],2):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('Login',{attrs:{"id":_vm.id}}),(!_vm.isMissingLocation)?_c('ServiceRequest',{attrs:{"id":_vm.id}}):_vm._e()],1)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }