<template>
  <simple-layout>
    <div
      style="
        background: #f7f5ee !important;
        padding-left: 10px;
        padding-right: 10px;
      "
    >
      <div class="container text-center vh-100 pt-5" v-if="isLoading">
        <img
          src="https://greenified.se/wp-content/themes/input/assets/images/Rolling-1.1s-81px.svg"
          width="50"
        />
        <p style="padding-top: 46px; text-align: center">Loading product...</p>
      </div>

      <div class="container asset-info" v-if="!isLoading">
        <template v-if="isMissingLocation">
          <ChangeLocation :id="id" @reload="getFixedAsset"></ChangeLocation>
        </template>

        <div class="row" v-for="section in product" :key="section.id">
          <div class="col">
            <h1 v-if="section.type === 'M'" class="headline">
              {{ section.headline.toLowerCase() }}
            </h1>
            <div class="row small-headline-wrapper" v-if="section.type === 'S'">
              <div class="col-4 small-headline">
                {{ section.headline.toLowerCase() }}
              </div>
              <div class="col-8">{{ section.text }}</div>
            </div>

            <div class="row small-headline-wrapper" v-if="section.type === 'L'">
              <div class="col-12 text-center">
                <a target="_blank" :href="'http://' + section.link">{{
                  section.headline
                }}</a>
              </div>
            </div>

            <div class="row small-headline-wrapper" v-if="section.type === 'D'">
              <div class="col-12 text-center">
                <a
                  :href="
                    api +
                    '/api/' +
                    $store.state.language +
                    '/item/' +
                    $route.params.id +
                    '/file/' +
                    section.id
                  "
                  >{{ section.headline }}</a
                >
              </div>
            </div>

            <div class="row small-headline-wrapper" v-if="section.type === 'B'">
              <div class="col-12 text-center">
                <img
                  class="img-fluid"
                  :src="
                    api +
                    '/api/' +
                    $store.state.language +
                    '/item/' +
                    $route.params.id +
                    '/image/' +
                    section.id
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="!isLoading">
        <div class="row">
          <div class="col">
            <Login :id="id"></Login>
            <ServiceRequest :id="id" v-if="!isMissingLocation"></ServiceRequest>
          </div>
        </div>
      </div>
    </div>
  </simple-layout>
</template>

<script>
import RentalAPI from "@/services/api/rental";
import Login from "@/components/Login";
import SimpleLayout from "@/layouts/SimpleLayout";
import ChangeLocation from "@/components/ChangeLocation";
import ServiceRequest from "@/components/ServiceRequest";
export default {
  name: "ViewFixetAsset",

  components: {
    Login,
    SimpleLayout,
    ChangeLocation,
    ServiceRequest,
  },

  mounted() {
    this.getFixedAsset();
  },
  props: {
    id: String,
  },
  data() {
    return {
      isLoading: true,
      product: {
        section: [],
      },
      api: process.env.VUE_APP_LARAVEL_API,
    };
  },
  watch: {
    "$route.params": {
      handler() {
        this.getFixedAsset();
        window.scrollTo(0, 0);
      },
      immediate: true,
    },
  },
  computed: {
    isMissingLocation() {
      let status = false;
      this.product.forEach((section) => {
        if (section.type === "U") {
          status = true;
        }
      });
      return status;
    },
  },
  methods: {
    getFixedAsset: function () {
      RentalAPI.getFixedAsset(this.$route.params.id)
        .then((response) => {
          this.setData(response.data);
        })
        .catch(() => {});
    },
    setData(payload) {
      this.product = payload;
      this.isLoading = false;
    },
  },
};
</script>
