<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-8" style="padding-top: 19px; padding-bottom: 19px">
            <img height="30" src="http://rental-api.greenified.se/api/logo" />
          </div>
          <div class="col-4">
            <button
              type="button"
              @click="toggleMenu"
              class="navbar-toggle collapsed"
              :class="{ open: menuOpen }"
              data-toggle="collapse"
              data-target="#main-navbar"
              aria-expanded="false"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar bar-1"></span>
              <span class="icon-bar bar-2"></span>
              <span class="icon-bar bar-2-2"></span>
              <span class="icon-bar bar-3"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="menuOpen">
      <div class="row">
        <div class="col">
          <ul class="navigation">
            <li><a href="#">Login</a></li>
            <li><a href="#">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<style lang="scss"></style>
<script>
export default {
  name: "SimpleLayout",
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu: function () {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>
