<template>
  <div class="pb-5">
    <div class="container text-center vh-100 pt-5" v-if="isLoading">
      <img
        src="https://greenified.se/wp-content/themes/input/assets/images/Rolling-1.1s-81px.svg"
        width="50"
      />
      <p style="padding-top: 46px; text-align: center">Please wait...</p>
    </div>
    <div class="" v-if="!showLoginForm && !showChangeLocation && !isLoading">
      <h1 class="headline mb-23">Manage Product</h1>
      <button type="submit" class="btn btn-black" @click="changeLocation">
        Change location
      </button>
    </div>
    <div
      class="loginform form"
      v-if="showLoginForm && !showChangeLocation && !isLoading"
    >
      <h1 class="headline mb-23">Login</h1>

      <div class="form-group mb-23">
        <input
          v-model="loginCredentials.username"
          type="text"
          name="email"
          class="form-control"
          id="email"
          aria-describedby="emailHelp"
          placeholder="Username"
        />
      </div>
      <div class="form-group mb-23">
        <input
          v-model="loginCredentials.password"
          type="password"
          name="password"
          id="password"
          class="form-control"
          aria-describedby="emailHelp"
          placeholder="Password"
        />
      </div>
      <div class="col-md-12 text-center">
        <button type="submit" class="btn btn-black" @click="doLogin">
          Login
        </button>
      </div>
    </div>
    <ChangeLocation
      :id="id"
      v-if="!showLoginForm && showChangeLocation && !isLoading"
    ></ChangeLocation>
  </div>
</template>

<script>
import RentalAPI from "@/services/api/rental";
import ChangeLocation from "@/components/ChangeLocation";
import Vue from "vue";
export default {
  name: "LoginForm",

  components: {
    ChangeLocation,
  },
  props: {
    id: String,
  },
  data() {
    return {
      showLoginForm: false,
      showChangeLocation: false,
      isLoading: false,
      loginCredentials: {
        username: "",
        password: "",
      },
      api: process.env.VUE_APP_LARAVEL_API,
    };
  },
  methods: {
    changeLocation() {
      const token = localStorage.getItem("token");
      if (token) {
        this.showChangeLocation = true;
      } else {
        this.showLoginForm = true;
      }
    },
    doLogin: function () {
      this.isLoading = true;
      RentalAPI.login(this.loginCredentials)
        .then((response) => {
          this.isLoading = false;
          this.showLoginForm = false;
          this.showChangeLocation = true;
          localStorage.setItem("token", response.data.access_token);
          Vue.prototype.$http.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");
        })
        .catch(() => {});
    },
  },
};
</script>
