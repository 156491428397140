import axios from "axios";
import store from "../store";

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_LARAVEL_API + "/api/" + store.state.language,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
