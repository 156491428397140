<template>
  <div class="pb-5">
    <div class="container text-center vh-100 pt-5 pb-5" v-if="isLoading">
      <img
        src="https://greenified.se/wp-content/themes/input/assets/images/Rolling-1.1s-81px.svg"
        width="50"
      />
      <p style="padding-top: 46px; text-align: center">Please wait...</p>
    </div>

    <button
      type="submit"
      class="btn btn-black"
      @click="showForm = 1"
      v-if="!showForm"
    >
      Service Request
    </button>
    <div class="loginform form" v-if="!isLoading && showForm">
      <h1 class="headline mb-23">New Service Request</h1>
      <div class="form-group mb-23">
        <textarea class="form-control" v-model="message"></textarea>
      </div>
      <div class="col-md-12 text-center">
        <button type="submit" class="btn btn-black" @click="sendServiceRequest">
          Send request
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RentalAPI from "@/services/api/rental";
export default {
  name: "ServiceRequest",
  props: {
    id: String,
  },

  data() {
    return {
      showForm: false,
      isLoading: false,
      message: "",
      showLoginForm: false,
      api: process.env.VUE_APP_LARAVEL_API,
    };
  },
  mounted() {},
  methods: {
    sendServiceRequest: function () {
      this.isLoading = true;
      RentalAPI.sendServiceRequest(this.id, this.message)
        .then(() => {
          this.isLoading = false;
          this.$toast.success("Message sent.", {
            timeout: 3000,
          });
          this.message = "";
          this.showForm = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.error("Unable to send service request.", {
            timeout: 3000,
          });
        });
    },
  },
};
</script>
